import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const About = () => {
  return (
    <Layout>
      <Seo title='About' />
      <StyledAboutPage>
        <h1>About Us</h1>
        <p className='about-text' >
        <span className='primary'>Adventure ‘n Beyond</span> has been running as a Christian Ministry for over 25 years. At the end of 2004, Paul Grobler took over the operation of this ministry. Our vision is to assist the community (church, school, youth group, corporate or individual) to live life, reaching their full potential and encouraging them to grow spiritually, socially, and physically through the use of Experiential Education principles in a safe, stimulating and adventurous setting.
        </p>

        <section>
          <h2>Our Staff</h2>
          <div className='content' >
            <StyledStaffMember>
              <img src="https://bit.ly/39Xh84O" alt="profile"/>
              <h3>Paul Grobler</h3>
              <h4>Director</h4>
              <p>Care for people. </p>
            </StyledStaffMember>
            <StyledStaffMember>
              <img src="https://bit.ly/39Xh84O" alt="profile"/>
              <h3>Cecillia Balebetse</h3>
              <h4>Bookings/Administration</h4>
              <p>Ecstatic personality. </p>
            </StyledStaffMember>
            <StyledStaffMember>
              <img src="https://bit.ly/39Xh84O" alt="profile"/>
              <h3>Richard Mead</h3>
              <h4>Activities Supervisor</h4>
              <p>True love for the outdoors. </p>
            </StyledStaffMember>
          </div>
        </section>
      </StyledAboutPage>
    </Layout>
  )
}

// --- STYLES ---

const StyledAboutPage = styled.main`
  color: #fff;
  text-align: center;

  span.primary { color: var(--primary-color); }

  h1 {
    font-size: 4rem;
    margin-top: 3rem;
  }

  h2 { font-size: 3rem; }

  p.about-text {
    font-size: 1.2rem;
    line-height: 1.4;
    max-width: 900px;
    margin: 3rem auto;
    padding: 0 1rem;
  }

  section {
    div.content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      margin: 4rem;
    }
  }
`

const StyledStaffMember = styled.div`
  margin-bottom: 3rem;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    color: #ccc;
    font-weight: 500;
  }

  p {
    color: #eee;
    margin-top: 1.4rem;
  }
`

export default About
